export default defineNuxtRouteMiddleware(async (to) => {
  const id_doc = queryValueToString(to.params.id_doc)
  if (!id_doc) {
    return
  }

  const { fetchDocument } = useDocumentsStore()
  const { error } = await useAsyncData(id_doc, () => fetchDocument({ id_doc }))

  if (error.value) {
    throw createError(error.value)
  }
})
